<template>
    <div class="PriceListSelector">
        <select id="listino" placeholder="Listino" class="ms-2" v-model="listino">
            <option value="0" v-if="permissions.has_public_prices">EUR</option>
            <option value="1" v-if="permissions.has_prices_gbp">UK</option>
            <option value="2" v-if="permissions.has_prices_cny">CHN</option>
            <option value="3" v-if="permissions.has_prices_rub">RUS</option>
            <option value="4" v-if="permissions.has_prices_usd">USD</option>
        </select>
    </div>
</template>

<script setup lang="ts">

const permissions = usePermissions();

const { listino } = usePermissions();

</script>
