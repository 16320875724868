<template>
    <AuthState>
        <div :class="showMenu ? 'flowHidden' : ''">
            <Header @menu-toggle="showMenu = !showMenu"/>
            <Menu :show="showMenu" @menu-toggle="showMenu = !showMenu">
                <slot/>
            </Menu>
        </div>
    </AuthState>
</template>

<script setup lang="ts">

import {EventTypes} from '~/constants';
import {initializeApp} from "firebase/app";
import {getMessaging, onMessage, getToken} from "firebase/messaging"

const {logEvent} = useEventLog();
const config = useRuntimeConfig();
const showMenu = ref(false)
const app = useEventBus<string>('app')
const unsubscribe = app.on((event) => {
    switch (event) {
        case 'menu-toggle':
            showMenu.value = !showMenu.value
            break
    }
});

useHead({
    link: [
        {rel:"manifest", href:"manifest.json"}
    ]
})

async function registerOfflineServiceWorker() {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.register("/service-worker.js", {
                scope: "/",
            });
            if (registration.installing) {
                console.log("Service worker installing");
            } else if (registration.waiting) {
                console.log("Service worker installed");
            } else if (registration.active) {
                console.log("Service worker active");
            }
        } catch (error) {
            console.error(`Registration failed with ${error}`);
        }
    }
};

async function enablePush(){
    // Receive push notifications
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');

            const app = initializeApp(config.public.firebase);
            const messaging = getMessaging(app);

            getToken(messaging, {vapidKey: config.public.firebase_public_key}).then((currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    $fetch("/api/register_push_token", {
                        method: "POST",
                        body: JSON.stringify({push_token: currentToken}),
                    }).then(() => {
                        console.log('Token sent to server');
                    });

                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });

            onMessage(messaging, (payload) => {
                console.log('Message received. ', payload);

                new Notification(payload.notification.title, {
                    body: payload.notification.body
                })

            });
        }
    });
}

onMounted(() => {
    logEvent(EventTypes.START_SESSION, {})

    enablePush();

    registerOfflineServiceWorker();

    window.addEventListener('beforeunload', function (e) {
        logEvent(EventTypes.CLOSE_SESSION, {})
    });
})

onUnmounted(() => {
    unsubscribe()
})

</script>
<style lang="scss">
.flowHidden {
    max-height: 100vh;
    overflow: hidden;
}
</style>
